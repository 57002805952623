<script setup lang="ts">
import {defineProps, defineModel} from "vue"

defineProps<{
  name: string
  required?: boolean
}>()
const model = defineModel<string>()
</script>

<template>
  <div class="txt_field">
    <input type="text" v-model="model" :required="required" placeholder=" ">
    <label>{{t(name)}} <small v-if="required" style="color: red">*</small></label>
    <span></span>
  </div>
</template>

<style scoped lang="sass">
</style>