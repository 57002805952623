import {config} from "@/config/config";
import axios from "axios";
import {RequestDataInterface} from "@/classes/RequestDataInterface";
import {ApiResponse} from "@/api/ApiResponse";
import api from "@/api/index";

const apiClient = axios.create({
    baseURL: config.apiBaseUrl,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    withXSRFToken: true,
})


export const ApiProcessor = {
    csrf: async () => {
        await axios.get('https://api.patriots.su/sanctum/csrf-cookie', {
            withCredentials: true,
        })
    },
    get: async (endpoint: string) => {
        await ApiProcessor.csrf()
        const url = config.apiBaseUrl + endpoint
        return apiClient.get(url)
            .then(s => {
                return s.data as ApiResponse
            })
    },
    post: async (data: RequestDataInterface) => {
        await ApiProcessor.csrf()
        const url = config.apiBaseUrl + data.endpoint
        console.log(data)
        return apiClient.post(url, data)
            .then(s => {
                return s.data as ApiResponse
            })
    },

}