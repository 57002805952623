

export const useLocalStore = <T>(key: string) => {
    const save = (value: T) => {
        localStorage.setItem(key, JSON.stringify(value))
    };

    const get = (): T | null => {
        const storedValue = localStorage.getItem(key)
        return storedValue ? JSON.parse(storedValue) : null
    };

    const remove = () => {
        localStorage.removeItem(key)
    }

    return { save, get, remove }
}