
export class UserConfig {
    locale: string | null = null
    theme: ThemeStyle = 'light'

    map (data: any): UserConfig {
        this.locale = data.locale
        this.theme = data.theme
        return this
    }
}

export type ThemeStyle = 'light' | 'dark'