<script setup lang="ts">
import {reactive} from "vue";
import InstagramIcon from "@/components/icons/InstagramIcon.vue";
import VKIcon from "@/components/icons/VKIcon.vue";

const data = reactive({
  links: {
    // x: "https://x.com/Footballer_MU",
    instagram: "https://instagram.com/moscowpatriotsofficial",
    vk: "https://vk.com/moscowpatriots"
    // linkedin: "https://linkedin.com/in/kirill-kobunov",
    // telegram: "https://t.me/methodius84"
  }
})
</script>

<template>
  <div class="container-wide footer">
    <div class="footer__separator"></div>
    <div class="footer__menu">
      <h1>Moscow Patriots</h1>
      <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
<!--        <router-link to="/contact">Contact</router-link>-->
      </nav>
    </div>
    <div class="footer__separator"></div>
    <div class="footer__container">
      <span class="footer__container__text">&copy; 2024 Moscow Patriots, All Rights Reserved</span>
      <div class="footer__container__icons">
<!--        <a :href="data.links.x" target="_blank"><TwitterIcon :color="'#080A45'"/></a>-->
<!--        <a :href="data.links.linkedin" target="_blank"><LinkedInIcon :color="'#080A45'"/></a>-->
        <a :href="data.links.instagram" target="_blank"><InstagramIcon :color="'#2c3e50'"/></a>
        <a :href="data.links.vk"><VKIcon :color="'#2c3e50'"/></a>
<!--        <a :href="data.links.telegram" target="_blank"><TelegramIcon :color="'#080A45'"/></a>-->
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>