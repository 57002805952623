import {ApiProcessor} from "@/api/ApiProcessor";
import {RequestDataInterface} from "@/classes/RequestDataInterface";


const api = {
    csrf: () => ApiProcessor.csrf(),
    application: {
        newbie: (data: RequestDataInterface) => ApiProcessor.post(data)
    },
    translations: (data: RequestDataInterface) => ApiProcessor.get(data.endpoint)
}

export default api