<script setup lang="ts">
import { defineProps, defineModel } from 'vue';
defineProps<{
  caption: string
}>()
const model = defineModel<boolean>()
</script>

<template>
  <div class="switch">
    <span class="switch__caption">{{t(caption)}}?</span>
    <div class="switch__input">
      <input id="switch-1" type="checkbox" class="switch__input__checkbox" v-model="model" />
      <label for="switch-1" class="switch__input__label">Switch</label>
    </div>
  </div>
</template>

<style scoped>

</style>