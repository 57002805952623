import './assets/css/main.sass'
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import VueBasicAlert from "vue-basic-alert";
import {useLocalization} from "@/utils/useLocalization";
import {useConfigStore} from "@/utils/store/useConfigStore";

const app = createApp(App)

const {initializeLocale, t} = useLocalization()

const {initializeConfig} = useConfigStore()

initializeLocale()
initializeConfig()

app.use(VueBasicAlert)
    .use(router)
    .mixin({
        methods: {
            t
        }
    })
    .mount('#app')
