<script setup lang="ts">

import {reactive, ref} from "vue";
import TextInput from "@/components/inputs/TextInput.vue";
import DateInput from "@/components/inputs/DateInput.vue";
import PhoneInput from "@/components/inputs/PhoneInput.vue";
import SwitchInput from "@/components/inputs/SwitchInput.vue";
import api from "@/api";
import {NewbieApplicationDTO} from "@/classes/NewbieApplicationDTO";
import {bus} from "@/utils/event-bus";
import {useLocalization} from "@/utils/useLocalization";

const {t} = useLocalization()

const initialState = {
  lastName: '',
  firstName: '',
  middleName: '',
  phone: '',
  email: '',
  telegram: '',
  birthDate: '',
  height: '',
  weight: '',
  knowsRules: false,
  experience: '',
  description: '',
}
const data = reactive({
  ...initialState
})
const send = async () => {
  const response = await api.application.newbie(new NewbieApplicationDTO().map(data))
      .then(s => s.Application)
      .catch(e => e.response)

  if (response === 'ok') {
    bus.emit('alert', {
      type: 'success',
      header: 'Success',
      message: 'Успешно отправили заявку',
      iconSize: null,
      iconStyle: null
    })
    resetForm()
  } else {
    bus.emit('alert', {
      type: 'error',
      header: 'Failed',
      message: 'Не смогли отправить заявку. ' + response.data.message,
      iconSize: null,
      iconStyle: null
    })
  }
}

const datePicked = (event: string) => {
  data.birthDate = event
}

const resetForm = () => {
  Object.assign(data, initialState)
}
</script>

<template>
  <div class="container">
    <form @submit.prevent="send">
<!--      <input type="hidden" :value="csrf" name="_token">-->
      <div class="fio">
        <TextInput v-model="data.lastName" :name="'Фамилия'" required/>
        <TextInput v-model="data.firstName" :name="'Имя'" required/>
        <TextInput v-model="data.middleName" :name="'Отчество'"/>
      </div>
      <div class="personal-info-container">
        <PhoneInput type="text" :name="'Телефон'" v-model="data.phone" required/>
        <TextInput type="email" :name="'Почта'" v-model="data.email" required/>
        <TextInput type="text" :name="'Телеграм'" v-model="data.telegram"/>
      </div>
      <div class="params">
        <DateInput :name="'Выберите дату рождения'" @pick="datePicked($event)"/>
        <TextInput type="text" :name="'Рост'" v-model="data.height" required/>
        <TextInput type="text" :name="'Вес'" v-model="data.weight" required/>
      </div>
      <div class="experience">
        <SwitchInput :caption="'Знаете ли Вы правила игры'" v-model="data.knowsRules"/>
        <TextInput type="text" :name="'Спортивный опыт, достижения'" v-model="data.experience" />
        <TextInput type="text" :name="'Расскажите о себе'" v-model="data.description" />
      </div>
      <div class="actions">
        <button type="submit">{{ t('Отправить') }}</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
</style>