<script setup lang="ts">
import {computed, defineEmits, defineProps, reactive, watchEffect} from "vue";
import {useLocalization} from "@/utils/useLocalization";

defineProps<{
  name:string,
  required?: boolean
}>()

const emit = defineEmits<{
  pick: [date: string]
}>()

const {t} = useLocalization()

const date = reactive({
  year: new Date().getFullYear(),
  month: new Date().getMonth() + 1,
  day: new Date().getDate()
})

const months = [
  {
    name: 'Январь',
    number: 1
  },
  {
    name: 'Февраль',
    number: 2
  },
  {
    name: 'Март',
    number: 3
  },
  {
    name: 'Апрель',
    number: 4
  },
  {
    name: 'Май',
    number: 5
  },
  {
    name: 'Июнь',
    number: 6
  },
  {
    name: 'Июль',
    number: 7
  },
  {
    name: 'Август',
    number: 8
  },
  {
    name: 'Сентябрь',
    number: 9
  },
  {
    name: 'Октябрь',
    number: 10
  },
  {
    name: 'Ноябрь',
    number: 11
  },
  {
    name: 'Декабрь',
    number: 12
  },
]

const startingYear = new Date().getFullYear()
const numberOfYears = 60

const days = computed(() => getMonthDays())
const getMonthDays = () => {
  const tmp = new Date(date.year, date.month - 1)
  tmp.setMonth(tmp.getMonth() + 1)
  tmp.setDate(0)
  return tmp.getDate()
}

watchEffect(() => {
  if (date.day > days.value) {
    date.day = 1
  }
  const datePicked = new Date(date.year, date.month - 1, date.day, 3)

  emit('pick', datePicked.toISOString().split('T')[0])
})
</script>

<template>
  <div class="date-picker">
    <div class="date-picker__name">{{t('Выберите дату рождения')}}:</div>
    <div class="date-picker__date">
      <div class="custom-select">
        <select v-model="date.day">
          <option selected disabled>{{ t('Выберите день') }}</option>
          <option v-for="(day, index) in days" :key="index" :value="day">
            {{day}}
          </option>
        </select>
      </div>

      <div class="custom-select">
        <select v-model="date.month">
          <option selected disabled>{{ t('Выберите месяц') }}</option>
          <option v-for="(month, index) in months" :value="month.number" :key="index">
            {{t(month.name) ?? month.name}}
          </option>
        </select>
      </div>

      <div class="custom-select">
        <select v-model="date.year">
          <option selected disabled>{{ t('Выберите год') }}</option>
          <option v-for="(year, index) in numberOfYears" :value="startingYear - index" :key="year">
            {{startingYear - (index)}}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>