<template>
  <nav>
    <router-link to="/">{{ t('Главная') }}</router-link> |
    <router-link to="/about">{{ t('О команде') }}</router-link>
  </nav>
  <vue-basic-alert :duration="300" :closeIn="3000" ref="alert"/>
  <RouterView v-slot="{ Component }">
    <OpacityTransition >
      <Component :is="Component"/>
    </OpacityTransition >
  </RouterView>
  <FooterComponent/>
</template>

<script setup lang="ts">
import OpacityTransition from "@/components/transitions/OpacityTransition.vue";
import {computed, onBeforeUnmount, onMounted, onUnmounted, ref} from "vue";
import {bus} from "@/utils/event-bus";
import {AlertMessageInterface} from "@/utils/AlertMessageInterface";
import {useConfigStore} from "@/utils/store/useConfigStore";
import {UserConfig} from "@/config/UserConfig";
import {useLocalization} from "@/utils/useLocalization";
import FooterComponent from "@/components/FooterComponent.vue";
// import {useRoute} from "vue-router";

const alert = ref(null)

const configStore = useConfigStore()
const {t} = useLocalization()

// onMounted(() => {
//   const route = useRoute()
//   const queryParams = computed(() => route.query)
//   console.log(queryParams.value.utm_source)
// })

onBeforeUnmount(() => {
  const config = configStore.config.value === null ?
      new UserConfig().map({locale: 'en', theme: 'light'}) :
      configStore.config.value

  configStore.setConfig(config)
})

const onAlert = (payload: AlertMessageInterface) => {
  alert.value.showAlert(
      payload.type,
      payload.message,
      payload.header,
      {
        iconSize: payload.iconSize,
        iconType: payload.iconStyle
      }
  )
}
bus.on('alert', (payload: AlertMessageInterface) => onAlert(payload))

onUnmounted(() => {
  bus.off('alert')
})
</script>