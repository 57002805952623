import {RequestDataInterface} from "@/classes/RequestDataInterface";

export class NewbieApplicationDTO implements RequestDataInterface {
    endpoint = 'applications/newbie'
    private last_name: string | null = null
    private first_name: string | null = null
    private middle_name: string | null = null
    private phone: string | null = null
    private email: string | null = null
    private telegram: string | null = null
    private birth_date: string | null = null
    private height: number | null = null
    private weight: number | null = null
    private does_know_rules: boolean | null = null
    private experience: string | null = null
    private description: string | null = null

    map(data: any): this {
        this.last_name = data.lastName
        this.first_name = data.firstName
        this.middle_name = data.middleName ?? null
        this.phone = data.phone
        this.email = data.email
        this.telegram = data.telegram ?? null
        this.birth_date = data.birthDate
        this.height = data.height
        this.weight = data.weight
        this.does_know_rules = data.knowsRules
        this.experience = data.experience ?? null
        this.description = data.description ?? null

        return this
    }
}