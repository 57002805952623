import {useLocalStore} from "@/utils/store/useLocalStore";
import {ref} from "vue";
import {UserConfig} from "@/config/UserConfig";

const configStore = useLocalStore<UserConfig>('UserConfig')

const config = ref<UserConfig | null>(null)
export const useConfigStore = () => {
    const setConfig = (nv: UserConfig) => {
        config.value = nv
        configStore.save(config.value)
    }

    const initializeConfig = () => {
        const savedConfig = configStore.get()
        savedConfig === null ? setConfig(new UserConfig().map({locale: 'ru', theme: 'light'})) : setConfig(savedConfig)
    }

    return {
        config,
        setConfig,
        initializeConfig
    }
}


