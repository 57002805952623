import api from "@/api";
import {ref} from "vue";
import {useLocalStore} from "@/utils/store/useLocalStore";

const translations = ref<{[key: string]: string}>({})
const currentLocale = ref<string>('ru')
export const useLocalization = () => {
    const fetchLocalizations = async (locale: string) => {
        try {
            const response = await api.translations({endpoint: `translations/get?locale=${locale}`})
                .then(s => s.Translations)
            translations.value = response.translations as {[key: string]: string}
            currentLocale.value = locale
            useLocalStore<string>('AppLocale').save(locale)
        }
        catch (error) {
            console.error('Error fetching useLocalization:', error)
        }
    }

    const detectBrowserLanguage = () => {
        const lang = navigator.language ?? 'ru'
        return (lang === 'en-US' ||
            lang === 'en' ||
            lang === 'ru') ? lang : 'ru'
    }

    const initializeLocale = () => {
        const savedLocale = useLocalStore<string>('AppLocale').get()
        const browserLang = detectBrowserLanguage()
        const locale = savedLocale || browserLang
        fetchLocalizations(locale)
    }

    const t = (key: string) => {
        return translations.value[key] ?? key
    }

    const capitalizeT = (text: string) => {
        return text.replace(/^\w/, (c) => c.toUpperCase())
    }

    return {
        initializeLocale,
        currentLocale,
        translations,
        t,
        capitalizeT
    }
}