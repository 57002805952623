<script setup lang="ts">
import {defineModel, defineProps, reactive} from "vue";
import {vMaska} from "maska/vue";
import {MaskInputOptions} from "maska";

defineProps<{
  name: string,
  required?: boolean
}>()

const options = reactive<MaskInputOptions>({
  mask: '+7 (###) ###-##-##',
  eager: true
})
const model = defineModel<string>()
</script>

<template>
  <div class="txt_field">
<!--    @todo доделать маску-->
    <input v-maska="options" type="text" inputmode="numeric" v-model="model" placeholder=" ">
    <label>{{t(name)}} <small v-if="required" style="color: red">*</small></label>
    <span></span>
  </div>
</template>

<style scoped>

</style>