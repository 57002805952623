<template>
  <div class="home">
    <img alt="MP-logo" src="../assets/MP-logo-ICON-01.png" style="width: 200px; height: 160px;" />
    <NewbieApplication/>
  </div>
</template>

<script setup lang="ts">
import NewbieApplication from "@/components/NewbieApplication.vue"; // @ is an alias to /src

document.title = 'Home'
</script>
